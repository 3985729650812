.proposal-form-tabs_content-tab_section-wrapper {
  &-tab {
    cursor: pointer;
    width: 100%;
    background-color: #c4c4c447;
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    position: relative;

    .common-table-actions {
      width: 200px;
      button {
        margin-right: 10px;
      }
    }
    .MuiTextField-root {
      margin-bottom: 0;
    }
    &-drag_icon {
      /*  position: absolute;
      top: 15px;
      left: 10px; */
      width: 30px;
      transform: scale(1.2);
    }
    &-title {
      display: block;
      height: 40px;
      line-height: 40px;
      font-style: normal;
      font-weight: 500;
      font-size: 19px;
      letter-spacing: 0.01em;
      color: #191919;
      flex: 1;
      min-width: 150px;
      white-space: nowrap;
      margin-right: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      .MuiTextField-root {
        min-width: 150px;
      }
    }
    &-new_title {
      flex: 0.8;
      position: relative;
    }
    &-total {
      text-align: center;
      font-weight: normal;
      font-size: 12px;
      letter-spacing: 0.01em;
      color: #000000;
      white-space: nowrap;

      p:last-child {
        font-weight: 600;
        color: #191919;
      }

      &:nth-child(1) {
        margin-right: 90px;
      }

      &-container {
        margin: 0 20px;
      }
    }

    @media (max-width: 499px) {
      &-total {
        span:last-child {
          margin: 0 10px 0 2px;
        }
      }

      &-drag_icon {
        transform: scale(1);
        /* top: 8px;
        left: 2px;

        & + div {
        } */
      }
    }
  }

  @media (max-width: 499px) {
    &-tab {
      padding: 5px;
      align-items: flex-start;

      .common-table-actions {
        width: 90px;
        // order: 2;
      }

      .proposal-form-tabs_content-tab_section-wrapper-tab-title {
        height: initial;
        line-height: initial;
        font-size: 12px;
        margin: 0 15px;
      }

      .totals {
        justify-content: center;
        width: 100%;
        margin: 0;

        & > div {
          font-size: 8px;
        }
      }
    }

    .custom-toggle-btn {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }

    &-content {
      padding: 0 5px !important;

      &-total_table_wrapper {
        &:first-child {
          padding-top: 5px !important;

          .MuiTextField-root {
            margin-bottom: 5px;
          }
        }
      }

      .ant-divider-horizontal {
        display: none;
      }
    }
  }
}

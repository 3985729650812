@import '../../../containers/MainContent/Orcatec/Layouts/AuthLayout/layoutConstants';
.settings-proposal {
  // @extend %settings;
  // @extend %mainButton;
  width: 1025px;
  .button_save {
    width: 50px;
    // margin-top: 10px;
    margin-left: 10px;
  }
  &-compact-view__title {
    margin-left: 10px;
  }

  &-industry {
    display: flex;
    // justify-content: space-between;
    margin: 20px 0 40px;

    & > * {
      flex: 0 0 48%;

      &.industry-buttons {
        display: flex;
        justify-content: space-between;
        padding: 0 4px;

        .dublicate-industry {
          display: flex;
          align-items: center;
          cursor: pointer;

          svg {
            margin-right: 10px;
          }

          span {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
  }

  &-compact-view {
    margin: 1rem 0 1.2rem;

    span {
      color: #191919;
      font-size: 16px;
      font-weight: 500;
    }

    .ant-switch-checked {
      background-color: #4e5ccf;
    }
  }

  &-editor {
    margin: 0 auto;
    margin-bottom: 1rem;
    max-width: 1000px;
  }

  &-tax-rate {
    line-height: 27px;
    text-align: center;
    display: flex;
    align-items: center;
    margin-right: auto;
    margin: 1rem 0;
    color: #191919;
    font-size: 16px;
    font-weight: 500;
    &-label {
      margin-right: 10px;
    }
  }

  &-checkboxes {
    // display: flex;
    // flex-direction: column;

    .ant-checkbox-wrapper {
      margin: 5px 0;
    }
  }

  &-logos-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #191919;
    border-bottom: 0.5px solid #cccccc;
    margin-bottom: 1rem;
    &-preview-btn {
      display: flex;
      align-items: center;
      i {
        margin-right: 10px;
        font-size: 20px;
      }
      white-space: nowrap;
      font-size: 12px;
      line-height: 18px;
      color: #4285f4;
      cursor: pointer;
    }
  }
  &-logos-wrapper {
    // overflow-x: auto;
    // overflow-y: unset;
    padding: 0 10px 10px 10px;
    height: auto;
    margin-bottom: 1rem;
  }
  &-logos-content {
    display: flex;
    justify-content: flex-start;
    //align-items: center;
  }
  &-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .main-button {
      background: #4c53ef26;
      color: #4285f4;
      font-weight: 500;
      font-size: 14px;
      border-color: #4c53ef26;
      width: 100%;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }
    .MuiFormControl-root {
      margin-bottom: 0;
    }
  }

  &-card:not(:last-child) {
    margin-right: 10px;
  }
  &-card:not(:nth-child(4)) {
    width: 124px;
    margin-top: 61px;
  }
  &-buttons-block {
    margin-top: 1.5rem;
    .ant-checkbox-wrapper > span:nth-child(2) {
      color: #191919;
    }
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    button {
      width: 100px;
    }
  }

  &-card:nth-child(4) {
    width: 186px;
  }

  &-modules {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 -15px;
    max-width: 770px;
    &-module {
      margin: 0 15px;
      width: 124px;
      text-align: center;
      div:first-child {
        color: #4285f4;
        margin-bottom: 1rem;
      }
      &-1,
      &-2,
      &-3 {
        margin: 0 15px;
        width: 124px;
        display: none;
      }
    }
  }
  .alert-message {
    width: 20%;
    position: fixed;
    display: block;
    z-index: 999;
    left: 75%;
    top: 20%;
  }
}

.email-text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  & > * {
    flex: 0 1 49%;
  }
}

.settings-proposal-add-logo-modal {
  @extend %settings;
  @extend %mainButton;
  .MuiInputBase-root,
  .MuiTextField-root {
    margin-bottom: 0;
  }
  .edit-button {
    font-size: 12px;
    letter-spacing: 0.01em;
    text-decoration-line: underline !important;
    color: #000000;
    cursor: pointer;
    transition: color 0.3s;
    //margin-top: 1.5rem;
  }
  .edit-button:hover {
    color: #4285f4;
  }
  .file-row {
    i {
      font-size: 1.5rem;
      //height: 40px;
      transition: color 0.3s;
      cursor: pointer;
      padding: 3px 0 0;
    }
    i:first-of-type {
      padding: 3px 10px 0 10px;
    }
    i:hover {
      color: #4285f4;
    }
  }

  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-footer {
    border-top: none;
    padding: 0 16px 1.5rem 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 926px) {
  .settings-proposal {
    &-modules {
      &-module {
        &-1,
        &-2,
        &-3 {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 568px) {
  .settings-proposal {
    &-buttons-block {
      justify-content: center;
      button {
        margin-left: 0;
        margin-top: 1.5rem;
        width: 100%;
      }
    }
  }
}

@media (max-width: 692px) {
  .settings-proposal {
    &-modules {
      &-module {
        &-2,
        &-3 {
          display: none;
        }
      }
    }
  }
}

.proposal-settings-header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1000px;
  & .settings-proposal-editor {
    width: 100%;
    margin: 0;
  }
}
